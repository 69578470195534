<template>
    <div class="home">
      <img class="body-background" src="ressources/mobile-body-back.png">
        <div class="overlay"></div>
          <div class="home-content">
            <img src="ressources/net-logo.png" class="net-app-logo">
            <div class="login-formsec" style="padding-top: 40px;">
              <form name="form"  ref="form">
              <div class="loginsec">
                <input type="text"  placeholder="Type Your Name"        v-model="form.name"       required class="login-input usernm-input">
                <input type="text"  placeholder="Email or Phone number" v-model="form.username"   required class="login-input usernm-input">
                <input placeholder="Password" v-model="form.password"  min="8"   type="password"  required class="login-input usernm-input">
                <input placeholder="Confirm Password" min="8" v-model="form.password_confirmation"  type="password"  required class="login-input usernm-input">
                <div class="inpchk-div">
                      <input class="checkinpt login-input" type="checkbox" id="horns" name="horns" v-model="agreeTermsAndConditions"  required />
                       <label for="horns"> Do you agree with our terms and conditions? </label>
                </div>
              </div>
              <div class="fogsubmit-sec">
                <button type="button" class="login-btn"  :disabled="disableRegisterBtn" @click="Signup()">Register</button>
              </div>
              </form>
              <div class="login-footersec">
                  <p v-show="error"   v-text="error" class="msg-txt"></p>
                  <p v-show="showEmailSentMessage"   v-text="success" class="msg-txt-success"></p>
                  <a href="/login" class="forgt-link">Do you have an account?</a>
              </div>
            </div>
          </div>
    </div>

</template>


<script>
import api from "@/api";

export default {
    name: "Register",
    data() {
        return {
            form: {
                name: "",
                username: "",
                email: "",
                password: "",
                password_confirmation: ""
            },
            error: "",
            success:"",
            isLoading: false,
            isRegistered: false,
            showEmailSentMessage: false,
            agreeTermsAndConditions: false,
            showTermsAndConditions: false,
            isValidForm: false,


        };
    },
    computed: {
        disableRegisterBtn() {
            return !!(
                !this.form.name ||
                !this.form.username ||
                !this.form.password ||
                !this.form.password_confirmation ||
                !this.agreeTermsAndConditions
            );
        }
    },
    methods:{
      async Signup() {

          try {
               /**  this.$refs.form.validate();
                if (!this.isValidForm) {
                    return;
                } */
                let  result=await api.auth.register(this.form);
                this.resetForm();
                this.error = "";
                this.success=result.data.message;
                this.showEmailSentMessage = true;
            } catch (err) {
                const errors = err.response.data.errors;
                if (err.response.status === 422 && errors) {
                    if(errors["email"]){
                        this.error = errors["email"] ? errors["email"][0] : "";
                    }
                    if(errors["username"]){
                        this.error = errors["username"] ? errors["username"][0] : "";
                    }
                    else{
                        this.error = errors["password"] ? errors["password"][0] : "";
                    }
                } else if (err.response.status === 419) {
                    this.error =
                        "The token is expired. Please refresh the page.";
                } else {
                    this.error = err.response.data.message;
                }
            } finally {
                this.isLoading = false;
            }
        },

        resetForm() {
            this.$refs.form.reset();
            this.form.name="";
            this.form.username="";
            this.form.password="";
            this.form.password_confirmation="";
            this.agreeTermsAndConditions="";
        }

    }


}
</script>

<style  type="scss">
*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    overflow:hidden;
    font-family: "Open Sans", sans-serif;
}

.home {
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.body-background{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(0,0,0,0.3);
}
.home-content {
  max-width: 600px;
  position: relative;
  top: 60%;
  color: #fff;
  z-index: 3;
  left: 50%;
  transform: translate(-50%, -60%);
  text-align: center;
}

.home-content .net-app-logo {
  width: 200px;
  height: auto;
}
.app-logosec {
  width: 300px;
  margin: 0 auto;
  display: flex;
}
.login-formsec
{
  max-width: 80%;
  margin: 0 auto;
  padding: 30px 60px 30px 60px;
  background: #ffffff82;
}
.login-formsec input{
  width: 100%;
  color: #fff;
  font-size: 18px;
  outline: none;
  box-shadow: none;
}
.log-sumtsec{
  position: relative;
}
.submit-sec
{
  float: right;

}

.submit-sec button
{
  background: #484848;
  border: none;
  padding: 15px;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  font-size: 18px;
  color: #fff;
}
.loginsec
{
  border: 15px solid #1c1e21;
  background: transparent;
}
.login-formsec input
{
  width: 100%;
  padding: 10px;
  background: #1c1e21;
  border: none;
  outline:0
}
.login-formsec .usernm-input{
  margin-bottom: 10px;
}
.submit-sec
{
  float: right;
  position: absolute;
  right: -30px;
  bottom: 30px;
}
.forgt-link
{
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  margin: 25px 0 15px 0;
}
.cret-accnt
{
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;

}
.msg-txt
{
  font-size: 16px;
  margin: 20px 0 0 20px;
  color: #f80a0a;
}
.msg-txt-success{
  color:green;
}
.loginsec .login-input::placeholder{
  color:#f5f5f5;
  font-size: 14px;
}
.forgt-link span{
    font-weight:700;
}
.fogheding {
  font-size: 30px;
  padding: 0 0 20px 0;
}
.forgtxt {
  font-size: 16px;
  padding: 0 20px 20px 20px;
  line-height: 25px;
}
.fogsubmit-sec .login-btn {
  padding: 13px 20px;
  margin-top: 15px;
  width: 100%;
  background: #484848;
  color: #fff;
  border: 1px solid #484848;
  font-size: 18px;
  font-weight: 500;
}

.forgt-loglink{
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  margin: 25px 0 15px 0;
}
.checkinpt{
  width: 20px !important;
}
.inpchk-div {
  background: #1C1E21;
}
.inpchk-div {
  background: #1C1E21;
  padding-top: 15px;
  text-align: left;
}
.inpchk-div label {
  font-size: 14px;
}
@media screen and (min-device-width: 300px) and (max-device-width: 500px) {
    .login-formsec
{
  max-width: 100%;
  margin-top: 30px;

}
.submit-sec
{

  right: -35px;
  bottom: 25px;
}
}

    </style>
